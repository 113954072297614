import React from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import gravNavy from "../../images/logos/grav-navy.png"
import gravText from "../../images/logos/grav_text_white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Form from "react-bootstrap/Form"
import { TransitionLink } from "../animation/TransitionLink"
import { DemoModalContext } from "../demo-modal-context"
import { NewsletterSuccessModal } from "../sitewide/NewsletterSuccessModal"
import Fade from "react-reveal/Fade"

export const Footer = () => {
  return (
    <>
      <Timeline />
      <JoinCommunity />
      <SiteFooter />
    </>
  )
}
const Timeline = () => {
  return (
    <Container fluid className="timeline-container p-5">
      <Container>
        <Row>
          <Col>
            <h1 className="mb-5">
              Proven Results In <br /> Weeks, Not Years
            </h1>
          </Col>
        </Row>
        <Fade>
          <Row className="timeline-row">
            <Col className="timeline-box one" lg={2}>
              <p>Discovery Session</p>
              <p className="timeline-time mobile-show">30 minutes</p>
            </Col>
            <Col className="timeline-box two" lg={2}>
              <p>Product Demo</p>
              <p className="timeline-time mobile-show">1 hour</p>
            </Col>
            <Col className="timeline-box three" lg={2}>
              <p>Benefits Case and Simulation</p>
              <p className="timeline-time mobile-show">3-4 days</p>
            </Col>
            <Col className="timeline-box four" lg={3}>
              <p>Implementation, Testing and Training </p>
              <p className="timeline-time mobile-show">4-8 weeks</p>
            </Col>
            <Col className="timeline-box" lg={3}>
              <p>Positive Return on Investment</p>
              <p className="timeline-time mobile-show"> &lt; 1-year</p>
            </Col>
          </Row>
        </Fade>
        <Row className="timeline-row mobile-hide">
          <Col className="timeline-time one" lg={2}>
            <p>30 minutes</p>
          </Col>
          <Col className="timeline-time two" lg={2}>
            <p>1 hour</p>
          </Col>
          <Col className="timeline-time three" lg={2}>
            <p>3-4 days</p>
          </Col>
          <Col className="timeline-time" lg={3}>
            <p>4-8 weeks</p>
          </Col>
          <Col className="timeline-time" lg={3}>
            <p> &lt; 1-year</p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
const JoinCommunity = () => {
  const [modalShow, setModalShow] = React.useState(false)

  const formSubmitter = ev => {
    ev.preventDefault()
    const form = ev.target

    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setModalShow(true)
      } else {
        console.log("uh oh form broke")
      }
    }
    xhr.send(data)
  }
  return (
    <Container fluid className="join-container p-4">
      <NewsletterSuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Row>
        <Col lg={1}>
          <Image fluid className="newsletter-logo" src={gravNavy} />
        </Col>
        <Col lg={7}>
          <h2 className="pt-4">Join Our Community</h2>
          <p className="text-left">
            get visibility into the new problems we are tackling through our
            solutions
          </p>
        </Col>
        <Col lg={4}>
          <Form
            className="mt-5"
            onSubmit={formSubmitter}
            action="https://formspree.io/f/xbjpgwew"
            method="POST"
          >
            <Row>
              <Col lg={8}>
                <Form.Group controlId="formBasicEmail">
                  <div id="join-our-community-email-input">
                    <Form.Control
                      style={{ color: "var(--navy-grav-theme)" }}
                      name="SignupEmail"
                      type="email"
                      placeholder="Enter Email Address Here..."
                    />
                  </div>
                  <Form.Text>
                    By signing up you will join our mailing list to stay
                    connected.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Button type="submit " className="mb-2 float-left" id="join-our-community-btn">
                  JOIN
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
const SiteFooter = () => {
  return (
    <Container fluid className="footer-container">
      <Row className="site-footer-row">
        <Col className="image-padding" lg={3} xs={12}>
          <Image fluid className="grav-logo" src={gravText} />
          <p className="text-left grav-excerpt">
            We aim to drive value for energy and commodity organizations by
            delivering AI enabled collaboration and decision support software.
          </p>
        </Col>
        <Col lg={6} xs={12} className="sitemap">
          <Row>
            <Col className="footer-link-column" lg={5} xs={12}>
              <h6 className="text-left">MARKETS</h6>
              <Row>
                <Col>
                  <p className="text-left link-header"> Refined Products</p>
                </Col>
                <Col>
                  <TransitionLink
                    className="text-right"
                    to="/refined_products/wholesalers"
                  >
                    Wholesalers
                  </TransitionLink>
                  <TransitionLink
                    className="text-right"
                    to="/refined_products/c_stores"
                  >
                    C-Stores
                  </TransitionLink>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="text-left link-header"> NGL's</p>
                </Col>
                <Col>
                  <TransitionLink className="text-right" to="/ngls/wholesalers">
                    Wholesalers
                  </TransitionLink>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <p className="text-left link-header"> Crude</p>
                </Col>
                <Col>
                  <TransitionLink
                    className="text-right"
                    to="/crude/first_producers"
                  >
                    First Purchasers
                  </TransitionLink>
                </Col>
              </Row> */}
            </Col>
            <Col
              className="solution-link-col"
              lg={{ span: 4, offset: 1 }}
              xs={12}
            >
              <h6 className="text-left">SOLUTIONS</h6>
              <TransitionLink className="bbd" to="/solution/bestbuy_dispatch">
                BestBuy and Dispatch
              </TransitionLink>
              <TransitionLink
                className="osp"
                to="/solution/online_selling_platform"
              >
                Online Selling Platform
              </TransitionLink>
              <TransitionLink className="pe" to="/solution/pricing_engine">
                Pricing Engine
              </TransitionLink>
              <TransitionLink className="cp" to="/solution/customer_portal">
                Customer Portal
              </TransitionLink>
              {/* <TransitionLink
                className="lco"
                to="/solution/lease_crude_optimization"
              >
                Lease Crude Optimization
              </TransitionLink> */}
            </Col>
            <Col className="insight-col" lg={2} xs={12}>
              <h6 className="text-left">INSIGHTS</h6>
              <TransitionLink className="lco" to="/insights">
                Blog
              </TransitionLink>
            </Col>
          </Row>
        </Col>
        <Col className="cta-container" lg={3} xs={12}>
          <div className="footer-cta">
            <h3>Ready for the next step?</h3>
            <Row className="pt-5 m-0 cta-link footer-next-step-anchor">
              <Col className="pl-0" xs={10} lg={10}>
                <DemoModalContext.Consumer>
                  {({ showModal, toggleModal }) => (
                    <a onClick={() => toggleModal()}>
                      <h6>REQUEST A DEMO</h6>
                    </a>
                  )}
                </DemoModalContext.Consumer>
              </Col>
              <Col xs={2} lg={2}>
                <FontAwesomeIcon
                  className="float-right"
                  icon={faChevronRight}
                />
              </Col>
            </Row>
            <Row className="pt-5 m-0 cta-link footer-next-step-anchor">
              <Col className="pl-0" xs={10} lg={10}>
                <TransitionLink to="/contact">
                  <h6>Contact Us</h6>
                </TransitionLink>
              </Col>
              <Col xs={2} lg={2}>
                <FontAwesomeIcon
                  className="float-right"
                  icon={faChevronRight}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
