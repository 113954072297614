import { Col, Row } from "react-bootstrap"
import React from "react"
import Lottie from "react-lottie"
import * as animationData from "./newsletter_success.json"
import { useEffect } from "react"
import { useState } from "react"

export const NewsletterSuccess = () => {
  const [res, setRes] = useState(200)
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.screen.width > 768) {
        setRes(400)
      }
    }
  }, [])
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div className="loading-container">
      <Row>
        <Col>
          <Lottie
            options={defaultOptions}
            height={res}
            width={res}
            isStopped={false}
            isPaused={false}
          />
        </Col>
      </Row>
      <h1 className="option-loading-message">
        <b> Thank you</b> for subscribing. We will keep you in the loop!
      </h1>
    </div>
  )
}
